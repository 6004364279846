import { Box, ChakraProps } from '@chakra-ui/react'
import { FC } from 'react'

export const CenteredButtonContainer: FC<ChakraProps> = ({ children, ...props }) => {
  return (
    <Box display="flex" justifyContent="center" {...props}>
      <Box display="flex" justifyContent="stretch" width="100%">
        {children}
      </Box>
    </Box>
  )
}
