import { Box, ChakraProps } from '@chakra-ui/react'
import { FC } from 'react'
import { useClientSetting } from 'src/clientSetting/Provider'
import MomoLogo from './momo.svg'
import YapLogo from './yap_logo.svg'
import KoalaLogo from './koala.svg'
import { clientSettings } from 'src/clientSetting/settings'
import { useHasMounted } from './useHasMounted'

export const Logo: FC<{} & ChakraProps> = ({ ...props }) => {
  const client = useClientSetting() || clientSettings.default

  const hasMounted = useHasMounted()
  if (!hasMounted) return null

  return (
    <Box {...props}>
      {client.id === clientSettings.momo.id ? (
        <MomoLogo />
      ) : client.id === clientSettings.koala_app.id || client.id === clientSettings.koala_web.id ? (
        <KoalaLogo />
      ) : client.id === clientSettings.yap.id ? (
        <YapLogo />
      ) : (
        <KoalaLogo />
      )}
    </Box>
  )
}
